import { HiExternalLink } from "react-icons/hi";
import type { Site } from "../types";

/**
 * Shortcut button to site.
 */
export const SiteButton = ({ site }: { site: Site | null }) => {
  const handleClick = () => {
    console.log("customdomain", site?.customDomain)
    if (site?.customDomain != null) {
      if (site?.customDomain.endsWith(".app")) {
        window.open("https://" + site?.customDomain || "", "_blank");
      } else {
        window.open("https://" + site?.customDomain + ".app" || "", "_blank");
      }
    } else {
      window.open("https://" + site?.subDomain + ".cloudlineapp.com" || "", "_blank");
    }
  }

  return (
    <div
      className="max-w-[380px] flex flex-grow justify-between items-center p-3 rounded-md border border-gray-300 cursor-pointer"
      onClick={handleClick}
    >
      <div className="flex items-center gap-2">
        <p className="text-base font-semibold">{site?.name}</p>
        <a href={`#${site?.id}`} onClick={event => event.stopPropagation()}>
          <p className="text-sm font-normal">read more</p>
        </a>
      </div>
      <HiExternalLink size="26px" />
    </div>
  );
};
