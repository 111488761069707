import type { Site } from "../types";
import { SiteButton } from "./SiteButton";

/**
 * List of ```SiteButton```'s.
 */
export const SiteButtons = ({ sites }: { sites: (Site | null)[] }) => (
  <div className="flex flex-wrap gap-2">
    {sites?.map(site => (
      <SiteButton key={site?.id} site={site} />
    ))}
  </div>
);
