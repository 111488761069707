import Image from "next/future/image";
import { useState } from "react";
import { TiLocationArrow } from "react-icons/ti";
import type { Site as TSite } from "../types";
import { ios } from "../utils/ios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

/**
 * Displays information about the organization.
 */
export const Site = ({ site }: { site: TSite | null }) => {
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  const handleClick = () => {
    window.open(domainURL, "_self");
  }
  // Apple device - Apple Maps
  // Other - Google Maps
  const mapUrl = ios()
    ? `http://maps.apple.com/?q=${site?.location?.lat},${site?.location?.lon}`
    : `https://maps.google.com/?q=${site?.location?.lat},${site?.location?.lon}`;

  var domainURL = "";
  if (site?.customDomain != null) {
    if (site?.customDomain.endsWith(".app")) {
      domainURL = "https://" + site?.customDomain;
    } else {
      domainURL = "https://" + site?.customDomain + ".app"
    }
  } else {
    domainURL = "https://" + site?.subDomain + ".cloudlineapp.com"
  }

  return (
    <div
      className="w-full max-w-[296px] relative flex flex-col justify-center gap-2 cursor-pointer"
      id={site?.id}
    >
      <Image
        alt="site-logo"
        src={`https://${site?.logoImage?.bucket}.s3.${site?.logoImage?.region}.amazonaws.com/public/${site?.logoImage?.key}`}
        height={100}
        width={296}
        className="object-contain"
        onLoadingComplete={() => setIsImageLoaded(true)}
        onClick={handleClick}
      />

      {!isImageLoaded && (
        <div className="w-full absolute top-0">
          <Skeleton height="100px" />
        </div>
      )}

      <div className="flex flex-col justify-center gap-3">
        <div className="text-sm font-semibold" onClick={handleClick}>{site?.name}</div>
        <p className="text-sm font-medium">{site?.description}</p>

        <a
          href={mapUrl}
          rel="noreferrer"
          target="_blank"
        >
          <div className="flex gap-2 items-center text-purple-900">
            <p className="text-sm font-medium text-gray-400">{site?.address}</p>
            <TiLocationArrow size="22px" />
          </div>
        </a>

        <p className="text-sm font-medium text-purple-900">
          <a href={domainURL}>{domainURL}</a>
        </p>
      </div>
    </div>
  );
};
