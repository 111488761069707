import { GraphQLResult } from "@aws-amplify/api";
import Amplify, { withSSRContext } from "aws-amplify";
import type { IncomingMessage } from "http";
import type { NextPage } from "next";
import Head from "next/head";
import { Organization } from "../components/Organization";
import { SiteButtons } from "../components/SiteButtons";
import { Sites } from "../components/Sites";
import { getOrganization } from "../graphql/queries";
import type { GetOrgBySubdomainQuery, Organization as TOrg } from "../types";

const config = {
  aws_appsync_graphqlEndpoint: process.env.ENDPOINT,
  aws_appsync_region: process.env.REGION || "eu-west-1",
  aws_appsync_apiKey: process.env.API_KEY
};

Amplify.configure({
  ...config,
  ssr: true
});

export async function getServerSideProps({ req }: { req: IncomingMessage }) {
  const { API } = withSSRContext({ req });

  const host = "escapade";
  // const host = req.headers.host || "escapade";

  const response = (await API.graphql({
    query: getOrganization,
    authMode: "API_KEY",
    variables: { subdomain: host }
  })) as GraphQLResult<GetOrgBySubdomainQuery>;

  return {
    props: {
      organization: response.data?.getOrgBySubdomain?.items[0]
    }
  };
}
/**
 * Main Page rendering all the components
 */
const Home: NextPage<{ organization: TOrg }> = ({ organization }) => {
  const filteredSites = organization.sites?.items.filter(
                          site => site?.id !== "8b37eb9c-3fb9-4eaa-b327-9be9ea6f6e83" && 
                                  site?.id !== "ac568754-ac4f-4547-accb-f2b2204c000c"
                          ) || []
  
  return (
    <div>
      <Head>
        <title>{organization.name}</title>
        <meta
          name="description"
          content={`Sites belonging to ${organization.name}`}
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main>
        <div className="max-w-[382px] flex flex-col gap-8">
          <Organization organization={organization} />
          <SiteButtons sites={filteredSites} />
          <Sites sites={filteredSites} />
        </div>
      </main>
    </div>
  );
};

export default Home;
