import type { Site as TSite } from "../types";
import { Site } from "./Site";

/**
 * List of ```Site```'s.
 */
export const Sites = ({ sites }: { sites: (TSite | null)[] | null }) => (
  <div>
    <h1 className="flex items-center text-2xl font-bold mb-6 after:content-[''] after:flex-1 after:ml-4 after:border-t">
      Our Sites
    </h1>

    <div className="p-6 flex flex-wrap justify-center gap-10">
      {sites?.map(site => (
        <Site key={site?.id} site={site} />
      ))}
    </div>
  </div>
);
