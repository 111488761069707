import Image from "next/image";
import { useState } from "react";
import type { Organization as TOrganization } from "../types";
import Skeleton from "react-loading-skeleton";

/**
 * Displays information about the organization.
 */
export const Organization = ({
  organization
}: {
  organization: TOrganization;
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  return (
    <div
      className="w-full flex flex-col items-center gap-4"
      aria-label="organisation-info"
    >
      <Image
        alt="organisation-logo"
        src={`https://${organization?.logoImage?.bucket}.s3.${organization?.logoImage?.region}.amazonaws.com/public/${organization?.logoImage?.key}`}
        width={200}
        height={200}
        onLoad={() => setIsImageLoaded(true)}
      />

      {!isImageLoaded && (
        <div className="absolute top-[24]">
          <Skeleton height="200px" width="200px" />
        </div>
      )}

      <p className="w-full text-center">{organization.description}</p>

      <p className="w-full text-center font-bold">
        Tap below to open our site apps
      </p>
    </div>
  );
};
